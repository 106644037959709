import { render, staticRenderFns } from "./SelectRider.vue?vue&type=template&id=514aad5f&scoped=true"
import script from "./SelectRider.vue?vue&type=script&lang=js"
export * from "./SelectRider.vue?vue&type=script&lang=js"
import style0 from "./SelectRider.vue?vue&type=style&index=0&id=514aad5f&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514aad5f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/apple/IdeaProjects/banling-empty-delivery/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('514aad5f')) {
      api.createRecord('514aad5f', component.options)
    } else {
      api.reload('514aad5f', component.options)
    }
    module.hot.accept("./SelectRider.vue?vue&type=template&id=514aad5f&scoped=true", function () {
      api.rerender('514aad5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/SelectRider.vue"
export default component.exports