<template>
  <div class="select-container">
    <div class="header">
      <div class="title">门店骑手</div>
    </div>
    <div class="content">
      <el-input placeholder="骑手姓名" v-model="keyword" size="small" :clearable="true" :style="{marginBottom: '12px'}">
        <el-button slot="append" icon="el-icon-search" size="mini" @click="search"></el-button>
      </el-input>
      <div v-loading="loading" class="riderContainer">
        <div v-for="(item) in riderList" :key="item.sid" @click="handleSelectRider(item)" class="item" :style="{color: currentSid===item.sid?'#409EFF':''}">
          {{item.cName}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getOrdFreightRiderList} from "@/api/delivery";

export default {
  name: "SelectRider",
  props: {
    riderData: {
      type: Object,
      required: true,
      default: null,
    }
  },
  data() {
    return {
      loading:false,
      keyword:'',
      riderList:[],
      currentSid:'',
    };
  },
  created() {
  },
  mounted() {
    this.currentSid = this.riderData?.sid;
    this.getRiderList();
  },
  methods: {
    getRiderList() {
      let params = {
        search:this.keyword,
        rStores:[],
        pageNum:1,
        pageSize:100000,
      }
      this.loading = true;
      getOrdFreightRiderList(params).then(result => {
        if (result) {
          this.riderList = result.rows;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    search() {
      this.currentSid = '';
      this.getRiderList();
    },
    handleSelectRider(item) {
      this.currentSid = item.sid;
      this.$emit('selectRider', {sid:item.sid,cName:item.cName});
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container{
  float: left;
  width: 220px;
  height: 100%;
  margin-right: 20px;
  border: 1px solid #ddd;
  .header{
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom:1px solid #ddd;
    padding-left: 15px;
    .title{
      width: 60px;
      height: 100%;
      border-bottom: 2px solid #409EFF;
    }
  }
  .content{
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 10px;
    .item{
      width: 100%;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      padding-left: 10px;
      cursor: pointer;
    }
  }
}
.riderContainer{
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
}
</style>
